require('../css/nav.css')
require("../css/landing.css");
require("../css/about.css")
require("../css/product.css")
require("../css/post.css")
require("../css/footer.css")
require("../css/contact.css")


export function ld_run()
{

    
}